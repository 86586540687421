.chartPie, .chartBar {
  width: 375px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  max-width: 1000px;
  margin: auto;
}
.page {
  max-width: 1000px;
  margin-bottom: 20px;
}
.floatImage {
  display: none;
  width: 100px;
  height: auto;
  position: relative;
  top: 40px;
  right: -80%;
}
.qrCode {
  width: 100px;
  height: auto;
  position: relative;
  float: right;
  margin-right: 20px;
  margin-top: 50px;
}
.titleText {
  background-color: #267644;
  color: #FFF;
  width: 90%;
  text-align: center;
  padding: 2px 0px;
}
.textBig {
  font-size: calc(14px + (24-14) * (80vw - 320px)/(1000-320));
}
.textNormal {
  font-size: calc(12px + (14-12) * (80vw - 320px)/(1000-320));
  text-align: center;
}
.textSmall {
  font-size: calc(10px + (12-10) * (80vw - 320px)/(1000-320));
}
.cellTable {
  border: 1px solid #333;
  padding: 2px 10px;
}
.blue {
  color: blue;
}
.green {
  color: green;
}
.red {
  color: red;
}


.header {
  padding: 2%;
  flex-direction: column;
  align-items: center;
}
.headerImage {
  width: 100%;
  margin-bottom: 30px;
}
.header .textBig {
  margin-bottom: 10px;
  font-weight: bold;
}

.about {
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.about img {
  width: 40%;
  margin-bottom: 30px;
}
.cardArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.card {
  background-color: #AAA;
  margin-bottom: 40px;
  width: 70%;
  height: 120px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 5px 10px;
}

.system {
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.system .sistemaImage {
  width: 450px;
  margin-top: 70px;
}

.spent {
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.spent .textBig {
  text-transform: uppercase;
  margin-bottom: 30px;
}
.spent .textSmall {
  margin-bottom: 10px;
}
.spent .cellTable {
  margin-bottom: 20px;
}
.table {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.table table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
}
.table table tr {
  text-align: center;
  font-weight: bold;
}

.proposal {
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}
.proposal .textNormal {
  color: #267644;
  font-weight: 500;
}
.proposal .titleText {
  margin-bottom: 20px;
  margin-top: 20px;
}
.proposal .proposalTable {
  border: 1px solid black;
  border-collapse: collapse;
  width: 100%;
}
.proposal thead {
  background-color: #267644;
  color: #FFF;
}
.proposal tbody {
  background-color: #aaa;
}
.proposal table tr {
  text-align: center;
  font-weight: bold;
}
.proposal .proposalGeneration {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}
.proposal .proposalGeneration td {
  border: 1px solid black;
}
.proposal .kitGerador {
  width: 90%;
  margin-bottom: 0px;
  border-collapse: collapse;
}
.proposal .kitGerador tr:nth-child(even) {
  background-color: #ccc;
}
.proposal .kitGerador tr {
  text-align: left;
  font-size: calc(10px + (12-10) * (80vw - 320px)/(1000-320));
}

.economy {
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 200px;
}
.economy .textBig {
  text-align: center;
  margin-bottom: 20px;
}
.economy .textSmall {
  margin-bottom: 10px;
}
.economy .cellTable {
  margin-bottom: 20px;
}

.payback {
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.payback .cellTable {
  padding: 5px 30px;
  color: blue;
  font-weight: bold;
  margin-bottom: 80px;
}

.modules {
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modules .textBig {
  margin-bottom: 20px;
}
.modules img {
  width: 60%;
}

.plate {
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.plate img {
  width: 80%;
  margin-bottom: 20px;
}
.titleTextGray {
  align-self: flex-start;
  background-color: #ccc;
  width: 80%;
  padding: 5px;
  margin-bottom: 20px;
}
.plate .textNormal {
  text-align: start;
}

.regularization {
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.regularization .textNormal {
  text-align: start;
}



@media only screen and (max-width: 600px) {
  .floatImage {
    top: -10px;
    right: -65%;
  }
  .system .sistemaImage {
    width: 320px;
  }
}

@media only print {
  .floatImage {
    display: block;
    right: -80%;
    top: 40px;
  }
  .header {
    margin-top: 50px;
    margin-bottom: 70px;
  }

  .about {
    padding-top: 150px;
    margin-bottom: 220px;
  }

  .system {
    padding-top: 200px;
    margin-bottom: 100px;
  }

  .spent {
    padding-top: 100px;
    margin-bottom: 250px;
  }

  .proposal {
    padding-top: 100px;
    margin-bottom: 0px;
  }

  .economy {
    padding-top: -40px;
    margin-bottom: 100px;
  }

  .economy .floatImage {
    right: -80%;
    top: 0px;
  }

  .payback {
    padding-top: 200px;
    margin-bottom: 450px;
  }

  .modules {
    padding-top: 50px;
    margin-bottom: 50px;
  }

  .plate {
    padding-top: 100px;
    margin-bottom: 200px;
  }

  .regularization {
    padding-top: 300px;
    margin-bottom: 100px;
  }
}